import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { renderDate } from "../utils";

const renderDevices = devices => {
  let renderedDevices;
  if (!devices || devices.length === 0) {
    renderedDevices = <div>None</div>;
  } else {
    renderedDevices = devices.map(n => {
      const date = format(n.currentStatus.CreatedAt, "DD/MM/YYYY HH:mm");
      return (
        <div key={n.Serial}>
          <div className="columns">
            <div className="column">
              <h1>
                <Link to={`/device/${n.Serial}`}>Serial: {n.Serial}</Link>
              </h1>
            </div>
            <div className="column">UUID: {n.UUID}</div>
            <div className="column">Last Status Update: {date}</div>
          </div>
        </div>
      );
    });
  }
  return (
    <div>
      <hr />
      <div>
        <h2 className="title is-4">Devices</h2>
      </div>
      {renderedDevices}
    </div>
  );
};

const renderContact = location => {
  const name = location.ContactName ? (
    <div className="column is-narrow" key={"name"}>
      Contact Name: {location.ContactName}
    </div>
  ) : (
      <div />
    );
  const number = location.ContactNumber ? (
    <div className="column is-narrow" key={"number"}>
      Contact Number: {location.ContactNumber}
    </div>
  ) : (
      <div />
    );
  const email = location.ContactEmail ? (
    <div className="column is-narrow" key={"email"}>
      Contact Email: {location.ContactEmail}
    </div>
  ) : (
      <div />
    );

  return [name, number, email];
};

export const Location = ({ location, devices }) => {
  let d;
  if (location) {
    d = (
      <div className="box" key={`location-${location.ID}`}>
        <div className="columns">
          <div className="column">
            <h1 className="subtitle">
              <Link to={`/company/${location.company.ID}`}>
                {location.company.Name}
              </Link>
            </h1>
            <h1 className="title">{location.Name}</h1>
            <h1 className="subtitle">{location.Address}</h1>
          </div>
          <div className="column is-narrow">
            <Link
              to={`/company/${location.company.ID}/location/${
                location.ID
                }/edit`}
              className="button is-info"
            >
              Edit
            </Link>
          </div>
        </div>
        <div className="columns">{renderContact(location)}</div>
        <div className="columns">
          <div className="column is-narrow">
            Created: {renderDate(location.CreatedAt)}
          </div>
          <div className="column is-narrow">
            Updated: {renderDate(location.UpdatedAt)}
          </div>
        </div>
        {renderDevices(devices)}
      </div>
    );
  }
  return [d];
};

Location.propTypes = {
  location: PropTypes.object,
  devices: PropTypes.array
};
