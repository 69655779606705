import format from "date-fns/format";

export const renderDate = item => {
    if (typeof item !== "string") {
        if (!item.Valid) {
            return "None";
        }
        item = item.Time;
    }

    return format(item, "DD/MM/YYYY HH:mm");
};
