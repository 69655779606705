import React, { Component } from "react";
import PropTypes from "prop-types";

export class RefreshButton extends Component {
  constructor(props) {
    super(props);
    this.state = { refreshAction: () => {} };
    this.startTime = this.startTime.bind(this);
    this.timer = this.timer.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ refreshAction: props.refreshAction });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  startTime() {
    this.state.refreshAction();
    var intervalId = setInterval(this.timer, 1000 * 120);
    this.setState({ intervalId, sync: true });
  }

  timer() {
    this.setState({ sync: false });
  }

  render() {
    const initial = (
      <div>
        <button
          className="button"
          onClick={() => {
            this.startTime();
          }}
        >
          Sync
        </button>
      </div>
    );
    const syncing = (
      <div>
        <button className="button" disabled>
          Syncing
        </button>
      </div>
    );
    return this.state.sync === true ? syncing : initial;
  }
}

RefreshButton.propTypes = {
  refreshAction: PropTypes.func
};
