import React, { Component } from "react";
import { CompanyListContainer } from "../containers";
import { Header } from "../components/header";
export class Companies extends Component {
  constructor(props) {
    super();
  }

  render() {
    let page = 0;
    if (this.props.location.search && this.props.location.search.length > 0) {
      const urlParams = new URLSearchParams(this.props.location.search);
      page = urlParams.get("page");
      page = parseInt(page) || 0;
    }

    return (
      <div>
        <Header />
        <div className="container">
          <CompanyListContainer page={page} />
        </div>
      </div>
    );
  }
}
