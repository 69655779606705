import React, { Component } from "react";
import { DeviceList } from "../components";
import PropTypes from "prop-types";
import axios from "axios";
import { debounce } from "../timing";

export class DeviceListContainer extends Component {
  // CHANGE THIS FOR PAGINATION SIZE :)
  limit = 20;

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      previousDisabled: true,
      nextDisabled: true,
      page: parseInt(props.page) || 0,
      offset: 0,
      hasPrevious: false,
      hasNext: false,
      searchQuery: props.search,
      searchType: props.searchType,
      statusOptions: [],
      searchStatus: [],
      linkParams: ""
    };

    this.getDevices = this.getDevices.bind(this);
    this.navigate = debounce(this.navigate.bind(this), 350);
    this.refreshDevices = this.refreshDevices.bind(this);
    this.getOffset = this.getOffset.bind(this);
    this.search = this.search.bind(this);
  }

  componentWillReceiveProps(props) {
    let { page, search, searchType } = props;
    page = parseInt(page) || 0;
    this.setState(
      {
        page,
        searchQuery: search || "",
        searchType: searchType || "any",
        offset: page * this.limit,
        hasPrevious: page > 0,
        hasNext: !(this.getOffset() + this.limit >= this.state.totalCount),
        linkParams: this.generateLinkParams()
      },
      this.setupSearchStatus
    );
  }

  generateLinkParams() {
    let statusSearchParams = "";
    if (this.state.searchStatus.length > 0) {
      this.state.searchStatus.forEach(v => {
        statusSearchParams += "&status[]=" + v.label;
      });
    }
    return (
      `?search=${this.state.searchQuery}&searchType=${this.state.searchType ||
        "any"}` + statusSearchParams
    );
  }

  navigate() {
    this.props.history.push({
      pathname: "/",
      search: this.generateLinkParams()
    });
  }

  search(item) {
    this.setState({ searchQuery: item.target.value, page: 0 }, this.navigate);
  }

  getDevices() {
    let statusSearchParams = "";
    if (this.state.searchStatus.length > 0) {
      this.state.searchStatus.forEach(v => {
        statusSearchParams += "&status[]=" + v.label;
      });
    }
    axios
      .get(
        `/api/devices?limit=${this.limit}&offset=${this.getOffset()}&search=${
          this.state.searchQuery
        }&searchType=${this.state.searchType || "any"}` + statusSearchParams
      )
      .then(resp => {
        const { data } = resp;
        const dd = data.Devices;
        let { devices } = this.state;
        devices = dd || [];
        this.setState({
          devices,
          totalCount: data.TotalCount,
          hasNext: !(this.getOffset() + this.limit >= data.TotalCount),
          hasPrevious: this.state.page > 0
        });
        return resp;
      });
  }

  setupSearchStatus() {
    const searchStatus = [];
    for (let i = 0; i < this.props.searchStatus.length; i++) {
      for (let j = 0; j < this.state.statusOptions.length; j++) {
        if (
          this.state.statusOptions[j].label.toLowerCase() ===
          this.props.searchStatus[i].toLowerCase()
        ) {
          searchStatus.push(this.state.statusOptions[j]);
          break;
        }
      }
    }
    this.setState({ searchStatus }, this.getDevices);
  }

  componentDidMount() {
    // set up options will call get devices once it maps out what it needs
    axios.get(`/api/statusOptions`).then(resp => {
      let data = [];
      if (resp.data) {
        data = resp.data.map(i => {
          return {
            value: i.ID,
            label: i.Label
          };
        });
      }
      this.setState({ statusOptions: data }, this.setupSearchStatus);
      return resp;
    });
  }

  componentWillUnmount() {
    this.setState = () => {};
  }

  refreshDevices() {
    axios.get("/api/devices/refresh").then(resp => {
      console.log(resp);
    });
  }

  getOffset() {
    return (this.state.page || 0) * this.limit;
  }

  render() {
    return (
      <DeviceList
        devices={this.state.devices}
        linkParams={this.state.linkParams}
        hasNext={this.state.hasNext}
        hasPrevious={this.state.hasPrevious}
        nextPage={this.state.page + 1}
        prevPage={this.state.page - 1}
        refreshAction={this.refreshDevices}
        searchAction={e => {
          e.persist();
          this.search(e);
        }}
        searchQuery={this.state.searchQuery}
        searchType={this.state.searchType}
        setSearchType={e => {
          this.setState({ searchType: e.target.value, page: 0 }, this.navigate);
        }}
        setStatus={e => {
          this.setState(
            {
              searchStatus: e
            },
            this.navigate
          );
        }}
        statusOptions={this.state.statusOptions}
        searchStatus={this.state.searchStatus}
      />
    );
  }
}

DeviceListContainer.propTypes = {
  page: PropTypes.number
};
