import React, { Component } from "react";
import { LocationFormContainer } from "../containers";
import { Header } from "../components/header";
export class LocationForm extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <LocationFormContainer
            companyID={this.props.match.params.companyID}
            locationID={this.props.match.params.locationID}
          />
        </div>
      </div>
    );
  }
}
