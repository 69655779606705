import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

export class LocationForm extends Component {
  constructor(props) {
    super(props);
    const existingLocation = props.location || {};
    this.state = {
      company: props.company,
      location: {
        ID: existingLocation.ID || undefined,
        Name: existingLocation.Name || "",
        Address: existingLocation.Address || "",
        ContactName: existingLocation.ContactName || "",
        ContactEmail: existingLocation.ContactEmail || "",
        ContactNumber: existingLocation.ContactNumber || "",
      }
    };
    this.updateLocationValue = this.updateLocationValue.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(props) {
    const existingLocation = props.location || {};
    this.setState({
      company: props.company,
      location: {
        ID: existingLocation.ID || undefined,
        Name: existingLocation.Name || "",
        Address: existingLocation.Address || "",
        ContactName: existingLocation.ContactName || "",
        ContactEmail: existingLocation.ContactEmail || "",
        ContactNumber: existingLocation.ContactNumber || "",
      }
    });
  }

  updateLocationValue(event) {
    const { location } = this.state;
    location[event.target.id] = event.target.value;
    this.setState({ location });
  }

  submit(event) {
    event.preventDefault();
    const { location } = this.state;

    const data = {
      Name: location.Name,
      CompanyID: this.state.company.ID,
      Address: location.Address || "",
      ContactName: location.ContactName || "",
      ContactEmail: location.ContactEmail || "",
      ContactNumber: location.ContactNumber || ""
    };
    this.props.submit(this.state.location.ID, data).then(data => {
      const redirectTo = this.state.location.ID
        ? `/location/${this.state.location.ID}`
        : `/company/${this.state.company.ID}`;
      this.setState({ redirectTo });
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <div className="box">
        <form onSubmit={this.submit}>
          <div>
            <div className="columns">
              <div className="column">
                <h1 className="title">
                  {this.state.company ? this.state.company.Name + ":" : ""}{" "}
                  Location
                </h1>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Name">
                    Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="Name"
                      type="text"
                      onChange={this.updateLocationValue}
                      value={this.state.location.Name}
                      placeholder="Name"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Address">
                    Address
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="Address"
                      type="text"
                      onChange={this.updateLocationValue}
                      value={this.state.location.Address}
                      placeholder="Address"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="ContactName">
                    Contact Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="ContactName"
                      type="text"
                      onChange={this.updateLocationValue}
                      value={this.state.location.ContactName}
                      placeholder="ex. Nobuo Uematsu"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="ContactNumber">
                    Contact Number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="ContactNumber"
                      type="text"
                      onChange={this.updateLocationValue}
                      value={this.state.location.ContactNumber}
                      placeholder="ex. 469 742 3111"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="ContactEmail">
                    Contact Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="ContactEmail"
                      type="text"
                      onChange={this.updateLocationValue}
                      value={this.state.location.ContactEmail}
                      placeholder="ex. example@example.com"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

LocationForm.propTypes = {
  location: PropTypes.object
};
