import React, { Component } from "react";
import { CompanyList } from "../components";

import axios from "axios";

export class CompanyListContainer extends Component {
  // Change this for pagination
  limit = 50;

  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      page: parseInt(props.page) || 0
    };
    this.getCompanies = this.getCompanies.bind(this);
    this.getOffset = this.getOffset.bind(this);
  }

  getCompanies() {
    axios
      .get(`/api/companies?limit=${this.limit}&offset=${this.getOffset()}`)
      .then(resp => {
        const { data } = resp;
        const cc = data.Companies;
        let { companies } = this.state;
        if (cc && cc.length > 0) {
          companies = cc;
          this.setState({
            companies,
            totalCount: data.TotalCount,
            hasNext: !(
              this.getOffset() + this.limit >=
              (this.state.totalCount || data.TotalCount)
            ),
            hasPrevious: this.state.page > 0
          });
        }
        return resp;
      });
  }

  componentWillReceiveProps(props) {
    let { page } = props;
    page = parseInt(page) || 0;
    this.setState(
      {
        page,
        offset: page * this.limit,
        hasPrevious: page > 0,
        hasNext: !(this.getOffset() + this.limit >= this.state.totalCount)
      },
      this.getCompanies
    );
  }

  getOffset() {
    return (this.state.page || 0) * this.limit;
  }

  componentDidMount() {
    this.getCompanies();
  }

  render() {
    return (
      <CompanyList
        companies={this.state.companies}
        hasNext={this.state.hasNext}
        hasPrevious={this.state.hasPrevious}
        nextPage={this.state.page + 1}
        prevPage={this.state.page - 1}
      />
    );
  }
}
