import React, { Component } from "react";
import { CompanyForm } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class CompanyFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyID: props.companyID,
      statusHistory: []
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.state.companyID) {
      axios.get(`/api/companies/${this.state.companyID}`).then(resp => {
        const { data } = resp;
        this.setState({ company: data });
        return resp;
      });
    }
  }

  submit(id, data) {
    if (id) {
      return axios.put(`/api/companies/${id}`, data).then(resp => {
        const { data } = resp;
        return data;
      });
    }

    return axios.post("/api/companies", data).then(resp => {
      const { data } = resp;
      return data;
    });
  }

  render() {
    return <CompanyForm company={this.state.company} submit={this.submit} />;
  }
}

CompanyFormContainer.propTypes = {
  companyID: PropTypes.string
};
