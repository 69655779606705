import React, { Component } from "react";
import axios from "axios";
import "./app.scss";
import Cookies from "universal-cookie";
import { BrowserRouter, Route } from "react-router-dom";
import {
  Home,
  Device,
  Company,
  Companies,
  Location,
  DeviceForm,
  CompanyForm,
  LocationForm,
  NoteForm,
  StatusForm,
  Statistics
} from "./pages";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { profile: null, halt_routing: true, error: null };
    this.cookies = new Cookies();
  }

  async componentDidMount() {
    // If we have `token_available`, we have just come from a login
    // and must POST up to get a new access_token.
    // Even if an access_token already exists in localStorage.
    if (this.cookies.get("token_available") === "true") {
      // Remove the indicator
      this.cookies.remove("token_available", { path: "/" });

      const response = await fetch('/api/auth/session_login', {
        method: 'POST',
        credentials: 'same-origin'
      });

      if (!response.ok) {
        let message = null;
        try {
          const json = await response.json();
          if (json.message) {
            message = json.message;
          }
        } finally {
          if (message === null) {
            message = ""
          } else {
            message = `(Server said: ${message})`;
          }

          this.setState({
            error: {
              title: "Failed to log in",
              message: "We had `token_available` but server wasn't happy. " + message,
            }
          });
        }
        return;
      }

      const json = await response.json();
      localStorage.setItem("access_token", json.access_token)
    }

    // Now check if access_token exists locally
    let access_token = localStorage.getItem("access_token");
    if (access_token) {
      const bearer = `Bearer ${access_token}`;

      // Check the access_token is still OK.
      const response = await fetch('/api/profile', {
        headers: {
          'Authorization': bearer,
        }
      });

      if (response.ok) {
        const json = await response.json();
        console.log("You are", json)

        // Set it as a default for the axios uses everywhere else. Because qaisjp is lazy.
        axios.defaults.headers.common['Authorization'] = bearer;
        this.setState({ halt_routing: false, profile: json })
        return;
      } else {
        let message = null;
        try {
          const json = await response.json();
          if (json.message) {
            message = json.message;
          }
        } finally {
          if (message === null) {
            message = ""
          } else {
            message = `(Server said: ${message})`;
          }

          this.setState({
            show_login_screen: true,
            error: {
              title: "Login failure",
              message: "Failed to log in. " + message,
            }
          })
        }
      }
    }

    // Show a login screen
    this.setState({ halt_routing: true, show_login_screen: true })
  }

  render() {
    if (this.state.halt_routing) {
      let content = "Internal error deciding what to do.";

      if (this.state.show_login_screen || this.state.error !== null) {
        content = <div>
          {this.state.show_login_screen && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <a className="button" href={`/saml/login?next=${encodeURIComponent(window.location.pathname)}`}>Log in using ShoboSSO</a>
          </div>}

          {this.state.error !== null && <article className="message is-danger" style={{ marginTop: "1em" }}>
            <div className="message-header">
              <p>{this.state.error.title}</p>
              <button className="delete" aria-label="delete"></button>
            </div>
            <div className="message-body">
              {this.state.error.message}
            </div>
          </article>}
        </div>;
      } else {
        content = <h1 className="title is-2">Loading...</h1>;
      }

      return <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
        <img src="/static/tenri.png" alt="Tenri Logo" />
        <h1 className="title is-1">Tenri Inventory</h1>
        {content}
      </div>;
    }

    return (
      <div>
        <BrowserRouter>
          <div>
            <Route path="/" exact component={Home} />
            <Route path="/statistics" exact component={Statistics} />
            <Route path="/devices/new" exact component={DeviceForm} />
            <Route path="/device/:deviceSerial" exact component={Device} />
            <Route path="/device/:deviceSerial/edit" component={DeviceForm} />
            <Route path="/device/:deviceSerial/new-note" component={NoteForm} />
            <Route path="/device/:deviceSerial/new-status" component={StatusForm} />
            <Route path="/companies/new" exact component={CompanyForm} />
            <Route path="/companies" exact component={Companies} />
            <Route path="/company/:companyID" exact component={Company} />
            <Route path="/company/:companyID/edit" component={CompanyForm} />
            <Route
              path="/company/:companyID/new-location"
              component={LocationForm}
            />
            <Route
              path="/company/:companyID/location/:locationID/edit"
              component={LocationForm}
            />
            <Route path="/locations/new" exact component={LocationForm} />
            <Route path="/location/:locationID" exact component={Location} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
