import React from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          Tenri Inventory
        </Link>

        <div
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>
      </div>
      <div className="navbar-end">
        <Link to="/" className="navbar-item">
          Devices
        </Link>
        <Link to="/companies" className="navbar-item">
          Companies
        </Link>
        <Link to="/statistics" className="navbar-item">
          Statistics
        </Link>
      </div>
    </nav>
  );
};
