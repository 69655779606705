import React from "react";
import { Link } from "react-router-dom";
import { DeleteButton } from "./deleteButton";
import format from "date-fns/format";
import PropTypes from "prop-types";

const renderStatusDate = item => {
  return format(item, "DD/MM/YYYY HH:mm");
};

const renderCompany = item => {
  if (item && item.Name) {
    return item.Name;
  }
  return "Unknown";
};

const renderLocation = item => {
  if (item) {
    return item.Name;
  }
  return "Unknown";
};

const renderNotes = (item, noteDeleteAction) => {
  if (!item.notes) item.notes = [];

  const notes = item.notes.map(n => {
    const date = format(n.CreatedAt, "DD/MM/YYYY");
    return (
      <div className="columns column-hover" key={n.ID}>
        <div className="column is-narrow">
          <strong>({date})</strong>
        </div>
        <div className="column">{n.Message}</div>
        <div className="column is-narrow">
          <DeleteButton targetID={n.ID} deleteAction={noteDeleteAction} />
        </div>
      </div>
    );
  });
  return (
    <div>
      <hr />

      <div className="columns">
        <div className="column">
          <h2 className="title is-4">Notes</h2>
        </div>
        <div className="column is-narrow">
          <Link to={`/device/${item.Serial}/new-note`} className="button is-info">
            New Note
          </Link>
        </div>
      </div>
      {notes}
    </div>
  );
};

const renderStatusRow = statusRow => {
  if (!statusRow) return;
  console.log(statusRow)
  return (
    <div className="columns" key={statusRow.ID}>
      <div className="column is-2">
        <span className="tag">{statusRow.StateLabel}</span>
      </div>
      <div className="column">
        <Link to={`/company/${statusRow.company.ID}`}>
          {renderCompany(statusRow.company)}
        </Link>
      </div>
      <div className="column">
        <Link to={`/location/${statusRow.location.ID}`}>
          {renderLocation(statusRow.location)}
        </Link>
      </div>
      <div className="column is-2">{renderStatusDate(statusRow.CreatedAt)}</div>
      <div className="column">{statusRow.Reason}</div>
    </div>
  );
};

const renderStatusHistory = history => {
  if (!history) {
    return (
      <div className="columns">
        <div className="column">None</div>
      </div>
    );
  }
  const result = history.map(i => {
    return renderStatusRow(i);
  });
  return result;
};

export const Device = ({ device, statusHistory, noteDeleteAction }) => {
  let d;
  if (device) {
    d = (
      <div className="box" key={device.Serial}>
        <div className="columns">
          <div className="column">
            <h1 className="title">Serial: {device.Serial}</h1>
            <h1 className="subtitle">UUID: {device.UUID}</h1>
          </div>
          <div className="column is-narrow">
            <Link to={`/device/${device.Serial}/edit`} className="button is-info">
              Edit
            </Link>
          </div>
        </div>
        <div className="columns">
          <div className="column is-narrow">
            Mac Address: {device.MacAddress}
          </div>
          <div className="column is-narrow">
            Managed By: {device.ManagedBy || "No One Assigned"}
          </div>
        </div>
        {renderNotes(device, noteDeleteAction)}
        <hr />
        <div className="columns">
          <div className="column">
            <h2 className="title is-4">Current Status</h2>
          </div>
          <div className="column is-narrow">
            <Link
              to={`/device/${device.Serial}/new-status`}
              className="button is-info"
            >
              Update Status
            </Link>
          </div>
        </div>
        {renderStatusRow(device.currentStatus)}
        <hr />
        <div>
          <h2 className="title is-4">Status History</h2>
        </div>
        {renderStatusHistory(statusHistory)}
      </div>
    );
  }
  return [d];
};

Device.propTypes = {
  device: PropTypes.object,
  statusHistory: PropTypes.array,
  noteDeleteAction: PropTypes.func
};
