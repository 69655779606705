import React, { Component } from "react";
import { DeviceListContainer } from "../containers";
import { Header } from "../components/header";
export class Home extends Component {
  render() {
    let page = 0;
    let search = "";
    let searchType = "any";
    let searchStatus = [];
    if (this.props.location.search && this.props.location.search.length > 0) {
      const urlParams = new URLSearchParams(this.props.location.search);
      page = urlParams.get("page");
      page = parseInt(page) || 0;
      search = urlParams.get("search") || "";
      searchType = urlParams.get("searchType") || "any";
      searchStatus = urlParams.getAll("status[]") || [];
    }
    return (
      <div>
        <Header />
        <div className="container">
          <DeviceListContainer
            page={page}
            search={search}
            searchType={searchType}
            searchStatus={searchStatus}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}
