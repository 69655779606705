import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
export class DeviceForm extends Component {
  constructor(props) {
    super(props);
    const existingDevice = props.device || {};
    this.state = {
      existingDevice,
      device: {
        UUID: existingDevice.UUID || "",
        MacAddress: existingDevice.MacAddress || "",
        Serial: existingDevice.Serial || "",
        ManagedBy: existingDevice.ManagedBy || ""
      }
    };
    this.updateDeviceValue = this.updateDeviceValue.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(props) {
    const existingDevice = props.device || {};
    this.setState({
      existingDevice,
      device: {
        UUID: existingDevice.UUID || "",
        MacAddress: existingDevice.MacAddress || "",
        Serial: existingDevice.Serial || "",
        ManagedBy: existingDevice.ManagedBy || ""
      }
    });
  }

  updateDeviceValue(event) {
    const { device } = this.state;
    device[event.target.id] = event.target.value;
    this.setState({ device });
  }

  submit(event) {
    event.preventDefault();
    const { existingDevice } = this.state;
    let data = existingDevice;
    data.UUID = this.state.device.UUID;
    data.MacAddress = this.state.device.MacAddress;
    data.Serial = this.state.device.Serial;
    data.ManagedBy = this.state.device.ManagedBy;

    this.props.submit(this.state.device.Serial, data).then(data => {
      this.setState({ redirectTo: `/device/${this.state.device.Serial}` });
      return data;
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <div className="box">
        <form onSubmit={this.submit}>
          <div>
            <div className="columns">
              <div className="column">
                <h1 className="title">Device: Basic Info</h1>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Serial">
                    Serial
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="Serial"
                      type="text"
                      onChange={this.updateDeviceValue}
                      value={this.state.device.Serial}
                      placeholder="Serial"
                      autoComplete="off"
                      required disabled
                    />
                  </div>
                  <p className="help">
                    Be careful changing this value as it is from an external
                    system!
                  </p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="UUID">
                    UUID
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="UUID"
                      type="text"
                      onChange={this.updateDeviceValue}
                      value={this.state.device.UUID}
                      placeholder="UUID"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <p className="help">
                    Be careful changing this value as it is from an external
                    system!
                  </p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="MacAddress">
                    MacAddress
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="MacAddress"
                      type="text"
                      onChange={this.updateDeviceValue}
                      value={this.state.device.MacAddress}
                      placeholder="Mac Address"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <p className="help">
                    Be careful changing this value as it is from an external
                    system!
                  </p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="ManagedBy">
                    Managed By
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="ManagedBy"
                      type="text"
                      onChange={this.updateDeviceValue}
                      value={this.state.device.ManagedBy}
                      placeholder="Managed By"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

DeviceForm.propTypes = {
  device: PropTypes.object
};
