import React, { Component } from "react";
import { DeviceForm } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class DeviceFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSerial: props.deviceSerial,
      statusHistory: []
    };
  }

  componentDidMount() {
    axios.get(`/api/devices/${this.state.deviceSerial}`).then(resp => {
      const { data } = resp;
      this.setState({ device: data });
      return resp;
    });
  }

  submit(serial, data) {
    return axios.put(`/api/devices/${serial}`, data).then(resp => {
      const { data } = resp;
      return data;
    });
  }

  render() {
    return <DeviceForm device={this.state.device} submit={this.submit} />;
  }
}

DeviceFormContainer.propTypes = {
  deviceSerial: PropTypes.string
};
