import React, { Component } from "react";
import { Device } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class DeviceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSerial: props.deviceSerial,
      statusHistory: []
    };
  }

  componentDidMount() {
    axios.get(`/api/devices/${this.state.deviceSerial}`).then(resp => {
      const { data } = resp;
      this.setState({ device: data });
      return resp;
    });
    axios
      .get(`/api/devices/${this.state.deviceSerial}/status?offset=1`)
      .then(resp => {
        const { data } = resp;
        this.setState({ statusHistory: data });
        return resp;
      });
  }

  deleteNote(id) {
    axios
      .delete(`/api/devices/${this.state.deviceSerial}/notes/${id}`)
      .then(resp => {
        axios.get(`/api/devices/${this.state.deviceSerial}`).then(resp => {
          const { data } = resp;
          this.setState({ device: data });
          return resp;
        });
        return resp;
      });
  }

  render() {
    return (
      <Device
        device={this.state.device}
        statusHistory={this.state.statusHistory}
        noteDeleteAction={i => this.deleteNote(i)}
      />
    );
  }
}

DeviceContainer.propTypes = {
  deviceSerial: PropTypes.string
};
