import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { renderDate } from '../utils';

const renderContact = location => {
  const name = location.ContactName ? (
    <div className="column is-narrow" key={"name"}>
      Contact Name: {location.ContactName}
    </div>
  ) : (
      <div />
    );
  const number = location.ContactNumber ? (
    <div className="column is-narrow" key={"number"}>
      Contact Number: {location.ContactNumber}
    </div>
  ) : (
      <div />
    );
  const email = location.ContactEmail ? (
    <div className="column is-narrow" key={"email"}>
      Contact Email: {location.ContactEmail}
    </div>
  ) : (
      <div />
    );

  return [name, number, email];
};

const renderLocations = (companyID, locations) => {
  if (!locations) locations = [];
  let renderedLocations = (
    <div key={"no-locations"} className="columns">
      <div className="column">None</div>
    </div>
  );
  if (locations.length !== 0) {
    renderedLocations = locations.map(n => {
      return (
        <div key={n.ID}>
          <div className="columns">
            <div className="column">
              <h3>
                <Link to={`/location/${n.ID}`}>{n.Name}</Link>- {n.Address}
              </h3>
            </div>
          </div>
          <div className="columns">{renderContact(n)}</div>
        </div>
      );
    });
  }
  return (
    <div>
      <hr />
      <div className="columns">
        <div className="column">
          <h2 className="title is-4">Locations</h2>
        </div>
        <div className="column is-narrow">
          <Link
            to={`/company/${companyID}/new-location`}
            className="button is-info"
          >
            New Location
          </Link>
        </div>
      </div>
      {renderedLocations}
    </div>
  );
};

export const Company = ({ company, locations }) => {
  let d;
  if (company) {
    d = (
      <div className="box" key={`company-${company.ID}`}>
        <div className="columns">
          <div className="column">
            <h1 className="title">{company.Name}</h1>
          </div>
          <div className="column is-narrow">
            <Link to={`/company/${company.ID}/edit`} className="button is-info">
              Edit
            </Link>
          </div>
        </div>
        <div className="columns">
          <div className="column is-narrow">
            Start Date: {renderDate(company.CreatedAt)}
          </div>
          <div className="column is-narrow">
            End Date: {renderDate(company.DeletedAt)}
          </div>
        </div>
        {renderLocations(company.ID, locations)}
      </div>
    );
  }
  return [d];
};

Company.propTypes = {
  company: PropTypes.object,
  locations: PropTypes.array
};
