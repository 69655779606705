import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

export class CompanyForm extends Component {
  constructor(props) {
    super(props);
    const existingCompany = props.company || {};
    this.state = {
      company: {
        ID: existingCompany.ID || undefined,
        Name: existingCompany.Name || "",
      }
    };
    this.updateCompanyValue = this.updateCompanyValue.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(props) {
    const existingCompany = props.company || {};
    this.setState({
      company: {
        ID: existingCompany.ID || undefined,
        Name: existingCompany.Name || "",
      }
    });
  }

  updateCompanyValue(event) {
    const { company } = this.state;
    company[event.target.id] = event.target.value;
    this.setState({ company });
  }

  submit(event) {
    event.preventDefault();
    const { company } = this.state;
    const data = {
      Name: company.Name,
    };
    this.props.submit(this.state.company.ID, data).then(data => {
      const redirectTo = this.state.company.ID
        ? `/company/${this.state.company.ID}`
        : `/company/${data.ID}/new-location`;
      this.setState({ redirectTo });
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <div className="box">
        <form onSubmit={this.submit}>
          <div>
            <div className="columns">
              <div className="column">
                <h1 className="title">Company: Basic Info</h1>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Name">
                    Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="Name"
                      type="text"
                      onChange={this.updateCompanyValue}
                      value={this.state.company.Name}
                      placeholder="Name"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    {this.state.company && this.state.company.ID
                      ? "Save"
                      : "Continue"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CompanyForm.propTypes = {
  company: PropTypes.object
};
