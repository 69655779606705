import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { renderDate } from '../utils';

export const CompanyList = ({
  companies,
  hasNext,
  hasPrevious,
  nextPage,
  prevPage
}) => {
  const list = companies.map(item => (
    <Link className="box" to={`/company/${item.ID}`} key={item.ID}>
      <div>
        <div className="columns">
          <div className="column">{item.Name}</div>
          <div className="column">Start Date: {renderDate(item.CreatedAt)}</div>
        </div>
      </div>
    </Link>
  ));
  const prevButton = hasPrevious ? (
    <Link
      to={{
        pathName: "/companies",
        search: `?page=${prevPage}`
      }}
      className="pagination-previous"
    >
      Previous
    </Link>
  ) : (
      <button className="pagination-previous" disabled>
        Previous
    </button>
    );
  const nextButton = hasNext ? (
    <Link
      to={{
        pathName: "/companies",
        search: `?page=${nextPage}`
      }}
      className="pagination-next"
    >
      Next
    </Link>
  ) : (
      <button className="pagination-next" disabled>
        Next
    </button>
    );
  const pagination = (
    <nav
      className="pagination"
      key="pagination"
      role="navigation"
      aria-label="pagination"
    >
      {prevButton}
      {nextButton}
    </nav>
  );

  const header = (
    <div className="box" key="-1111">
      <div className="columns">
        <div className="column">
          <h1 className="title">Companies</h1>
        </div>
        <div className="column is-narrow">
          <Link to="/companies/new" className="button is-primary">
            New Company
          </Link>
        </div>
      </div>
    </div>
  );
  return [header, list, pagination];
};

CompanyList.propTypes = {
  copmanies: PropTypes.array
};
