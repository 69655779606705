import React, { Component } from "react";
import { LocationForm } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class LocationFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyID: props.companyID,
      locationID: props.locationID,
      statusHistory: []
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.props.companyID) {
      axios.get(`/api/companies/${this.state.companyID}`).then(resp => {
        const { data } = resp;
        this.setState({ company: data });
        return resp;
      });
    }
    if (this.state.locationID) {
      axios.get(`/api/locations/${this.state.locationID}`).then(resp => {
        const { data } = resp;
        this.setState({ location: data });
        return resp;
      });
    }
  }

  submit(id, data) {
    if (id) {
      return axios.put(`/api/locations/${id}`, data).then(resp => {
        const { data } = resp;
        return data;
      });
    }

    return axios.post("/api/locations", data).then(resp => {
      const { data } = resp;
      return data;
    });
  }

  render() {
    return (
      <LocationForm
        company={this.state.company}
        location={this.state.location}
        submit={this.submit}
      />
    );
  }
}

LocationFormContainer.propTypes = {
  companyID: PropTypes.string,
  locationID: PropTypes.string
};
