import React, { Component } from "react";
import { Location } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class LocationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationID: props.locationID,
      statusHistory: []
    };
  }

  componentDidMount() {
    axios.get(`/api/locations/${this.state.locationID}`).then(resp => {
      const { data } = resp;
      this.setState({ location: data });
      return resp;
    });
    axios
      .get(`/api/locations/${this.state.locationID}/devices?offset=0&limit=200`)
      .then(resp => {
        const { data } = resp;
        this.setState({ devices: data.Devices });
        return resp;
      });
  }

  render() {
    return (
      <Location location={this.state.location} devices={this.state.devices} />
    );
  }
}

LocationContainer.propTypes = {
  locationID: PropTypes.string
};
