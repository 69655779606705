import React from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { RefreshButton } from "./refreshButton";
import Select from "react-select";
import PropTypes from "prop-types";

const renderStatus = item => {
  if (item.currentStatus) {
    return <span className="tag is-info">{item.currentStatus.StateLabel}</span>;
  }
  return <span className="tag">Unknown</span>;
};

const renderStatusDate = item => {
  if (!item.currentStatus) {
    return "Unknown";
  }
  return format(item.currentStatus.CreatedAt, "DD/MM/YYYY HH:mm");
};

const renderCompany = item => {
  if (
    item.currentStatus &&
    item.currentStatus.company
  ) {
    return item.currentStatus.company.Name;
  }
  return "Unknown";
};

const renderLocation = item => {
  if (
    item.currentStatus &&
    item.currentStatus.location
  ) {
    return item.currentStatus.location.Name;
  }
  return "";
};

export const DeviceList = ({
  devices,
  refreshAction,
  linkParams,
  hasPrevious,
  hasNext,
  prevPage,
  nextPage,
  searchQuery,
  searchAction,
  searchType,
  setSearchType,
  statusOptions,
  setStatus,
  searchStatus
}) => {
  const list = devices.map(item => (
    <Link
      className="box device-item"
      to={`/device/${item.Serial}`}
      key={item.Serial}
    >
      <div className="">
        <div className="columns">
          <div className="column is-4">
            <div className="title is-5">{item.Serial}</div>
            <div>{item.UUID}</div>
            <div>MAC: {item.MacAddress}</div>
          </div>
          <div className="column">{renderStatus(item)}</div>
          <div className="column">
            <div>{renderCompany(item)}</div>
            <div>{renderLocation(item)}</div>
          </div>
          <div className="column">{renderStatusDate(item)}</div>
          <div className="column">{item.ManagedBy || "No One Assigned"}</div>
        </div>
      </div>
    </Link>
  ));
  const title = (
    <div key={"title-1"}>
      <div className="box">
        <div className="columns">
          <div className="column">
            <h1 className="title">Devices</h1>
          </div>
          <div className="column is-narrow">
            <RefreshButton refreshAction={refreshAction} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <input
              type="text"
              className="input"
              onChange={searchAction}
              value={searchQuery}
              placeholder="Search Serial, UUID, or Mac Address"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  name="searchType"
                  value="any"
                  onChange={setSearchType}
                  checked={searchType === "any"}
                />
                Any
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="searchType"
                  value="UUID"
                  onChange={setSearchType}
                  checked={searchType === "UUID"}
                />
                UUID
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="searchType"
                  value="serial"
                  onChange={setSearchType}
                  checked={searchType === "serial"}
                />
                Serial
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="searchType"
                  value="macAddress"
                  onChange={setSearchType}
                  checked={searchType === "macAddress"}
                />
                Mac Address
              </label>
            </div>
          </div>
          <div className="column">
            <Select
              placeholder="State Filter"
              isMulti
              value={searchStatus}
              options={statusOptions}
              onChange={setStatus}
              isDisabled={statusOptions.length === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const prevButton = hasPrevious ? (
    <Link
      to={{
        pathName: "/",
        search: `${
          linkParams.length > 0 ? linkParams + "&" : "?"
          }page=${prevPage}`
      }}
      className="pagination-previous"
    >
      Previous
    </Link>
  ) : (
      <button className="pagination-previous" disabled>
        Previous
    </button>
    );
  const nextButton = hasNext ? (
    <Link
      to={{
        pathName: "/",
        search: `${
          linkParams.length > 0 ? linkParams + "&" : "?"
          }page=${nextPage}`
      }}
      className="pagination-next"
    >
      Next
    </Link>
  ) : (
      <button className="pagination-next" disabled>
        Next
    </button>
    );
  const pagination = (
    <nav
      className="pagination"
      key="pagination"
      role="navigation"
      aria-label="pagination"
    >
      {prevButton}
      {nextButton}
    </nav>
  );
  const headers = (
    <div className="box" key={"header-1"}>
      <div className="columns">
        <div className="column is-4">
          <div>
            <strong>Serial</strong>
          </div>
          <div>UUID</div>
          <div>Mac Address</div>
        </div>
        <div className="column">
          <strong>State</strong>
        </div>
        <div className="column">
          <strong>Current Location</strong>
        </div>
        <div className="column">
          <strong>Last Updated</strong>
        </div>
        <div className="column">
          <strong>Managed By</strong>
        </div>
      </div>
    </div>
  );
  return [title, headers, list, pagination];
};

DeviceList.propTypes = {
  devices: PropTypes.array,
  refreshAction: PropTypes.func,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  prevPage: PropTypes.number,
  nextPage: PropTypes.number
};
