import React, { Component } from "react";
import { Statistics } from "../components";
import axios from "axios";

export class StatisticsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {}
    };
  }

  componentDidMount() {
    axios.get(`/api/statistics`).then(resp => {
      const { data } = resp;
      this.setState({ data, loaded: true });
      return resp;
    });
  }

  render() {
    return <Statistics isLoaded={this.state.loaded} data={this.state.data} />;
  }
}
