import React, { Component } from "react";
import { StatusForm } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class StatusFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSerial: props.deviceSerial,
      statusHistory: [],
      lastCalledCompanyID: undefined,
      statusOptions: []
    };

    this.updateCompany = this.updateCompany.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateCompany(selection) {
    if (this.state.lastCalledCompanyID !== selection.value) {
      axios.get(`/api/companies/${selection.value}/locations`).then(resp => {
        let locationOptions = [];
        if (resp.data) {
          locationOptions = resp.data.map(i => {
            return {
              value: i.ID,
              label: `${i.Name} - ${i.Address}`
            };
          });
        }
        this.setState({ locationOptions });
      });
      // this is split out because we want to update it even if it fails
      this.setState({ lastCalledCompanyID: selection.value });
    }
  }

  componentDidMount() {
    axios
      .get(`/api/devices/${this.state.deviceSerial}`)
      .then(resp => {
        const { data } = resp;
        this.setState({ device: data });
        return data;
      })
      .then(deviceData => {
        if (deviceData.currentStatus) {
          this.setState({
            lastCalledCompanyID: deviceData.currentStatus.CompanyID
          });
          return axios.get(
            `/api/companies/${
            deviceData.currentStatus.CompanyID
            }/locations`
          );
        } else {
          return {};
        }
      })
      .then(resp => {
        if (resp.data) {
          const locationOptions = resp.data.map(i => {
            return {
              value: i.ID,
              label: `${i.Name} - ${i.Address}`
            };
          });
          this.setState({ locationOptions });
        }
      });

    axios.get(`/api/companies?limit=200`).then(resp => {
      let data = [];
      if (resp.data.Companies) {
        data = resp.data.Companies.map(i => {
          return {
            value: i.ID,
            label: i.Name
          };
        });
      }
      this.setState({ companyOptions: data });
      return resp;
    });
    axios.get(`/api/statusOptions`).then(resp => {
      let data = [];
      if (resp.data) {
        data = resp.data.map(i => {
          return {
            value: i.ID,
            label: i.Label
          };
        });
      }
      this.setState({ statusOptions: data });
      return resp;
    });
  }

  submit(id, data) {
    return axios.post(`/api/devices/${id}/status`, data).then(resp => {
      const { data } = resp;
      return data;
    });
  }

  render() {
    return (
      <StatusForm
        submit={this.submit}
        device={this.state.device}
        updateCompany={this.updateCompany}
        locationOptions={this.state.locationOptions}
        companyOptions={this.state.companyOptions}
        statusOptions={this.state.statusOptions}
      />
    );
  }
}

StatusFormContainer.propTypes = {
  deviceSerial: PropTypes.string
};
