import React, { Component } from "react";
import { CompanyContainer } from "../containers";
import { Header } from "../components/header";
export class Company extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <CompanyContainer companyID={this.props.match.params.companyID} />
        </div>
      </div>
    );
  }
}
