import React, { Component } from "react";
import { StatisticsContainer } from "../containers";
import { Header } from "../components/header";
export class Statistics extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <StatisticsContainer />
        </div>
      </div>
    );
  }
}
