import React, { Component } from "react";
import PropTypes from "prop-types";

export class DeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.targetID,
      deleteAction: props.deleteAction || function() {}
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      id: props.targetID,
      deleteAction: props.deleteAction || function() {}
    });
  }

  render() {
    const initial = (
      <div>
        <button
          className="button is-small"
          onClick={() => {
            this.setState({ showConfirm: true });
          }}
        >
          Delete
        </button>
      </div>
    );
    const confirm = (
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-danger is-small"
            onClick={() => {
              this.state.deleteAction(this.state.id);
            }}
          >
            Delete Confirm
          </button>
        </p>
        <p className="control">
          <button
            className="button is-small"
            onClick={() => {
              this.setState({ showConfirm: false });
            }}
          >
            Cancel
          </button>
        </p>
      </div>
    );
    return this.state.showConfirm ? confirm : initial;
  }
}

DeleteButton.propTypes = {
  targetID: PropTypes.number,
  deleteAction: PropTypes.func
};
