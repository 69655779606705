import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

export class NoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: {},
      note: {
        Message: ""
      }
    };
    this.updateNoteValue = this.updateNoteValue.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      device: props.device
    });
  }

  updateNoteValue(event) {
    const { note } = this.state;
    note[event.target.id] = event.target.value;
    this.setState({ note });
  }

  submit(event) {
    event.preventDefault();
    const data = {
      Message: this.state.note.Message
    };

    this.props.submit(this.state.device.Serial, data).then(data => {
      this.setState({ redirectTo: `/device/${this.state.device.Serial}` });
      return data;
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <div className="box">
        <form onSubmit={this.submit}>
          <div>
            <div className="columns">
              <div className="column">
                <h1 className="title">Note for {this.state.device.Serial}</h1>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Message">
                    Message
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="Message"
                      type="text"
                      onChange={this.updateNoteValue}
                      value={this.state.note.Message}
                      placeholder="Ex. Box is torn"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

NoteForm.propTypes = {
  device: PropTypes.object
};
