import React, { Component } from "react";
import { DeviceContainer } from "../containers";
import { Header } from "../components/header";
export class Device extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <DeviceContainer deviceSerial={this.props.match.params.deviceSerial} />
        </div>
      </div>
    );
  }
}
