import React, { Component } from "react";
import { Company } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class CompanyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyID: props.companyID,
      statusHistory: []
    };
  }

  componentDidMount() {
    axios.get(`/api/companies/${this.state.companyID}`).then(resp => {
      const { data } = resp;
      this.setState({ company: data });
      return resp;
    });
    axios.get(`/api/companies/${this.state.companyID}/locations`).then(resp => {
      const { data } = resp;
      this.setState({ locations: data });
      return resp;
    });
  }

  render() {
    return (
      <Company company={this.state.company} locations={this.state.locations} />
    );
  }
}

CompanyContainer.propTypes = {
  companyID: PropTypes.string
};
