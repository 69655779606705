import React, { Component } from "react";
import { NoteForm } from "../components";
import PropTypes from "prop-types";
import axios from "axios";

export class NoteFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSerial: props.deviceSerial,
      statusHistory: []
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    axios.get(`/api/devices/${this.state.deviceSerial}`).then(resp => {
      const { data } = resp;
      this.setState({ device: data });
      return resp;
    });
  }

  submit(id, data) {
    return axios.post(`/api/devices/${id}/notes`, data).then(resp => {
      const { data } = resp;
      return data;
    });
  }

  render() {
    return <NoteForm device={this.state.device} submit={this.submit} />;
  }
}

NoteFormContainer.propTypes = {
  deviceSerial: PropTypes.string
};
