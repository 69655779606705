import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";

export class StatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: {},
      status: {
        Status: "",
        Reason: "",
        CompanyID: "",
        Company: {},
        LocationID: "",
        Location: {},
        StatusOption: null,
      },
      init: false,
      updateCompany: () => { },
      companyOptions: props.companyOptions || [],
      locationOptions: props.locationOptions || []
    };
    this.updateStatusValue = this.updateStatusValue.bind(this);
    this.updateStatusCompany = this.updateStatusCompany.bind(this);
    this.updateStatusLocation = this.updateStatusLocation.bind(this);
    this.updateStatusOption = this.updateStatusOption.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(props) {
    let currStatus = this.state.status;
    if (props.device && this.state.init !== true) {
      currStatus.CompanyID = props.device.currentStatus
        ? props.device.currentStatus.CompanyID
        : undefined;
      currStatus.LocationID = props.device.currentStatus
        ? props.device.currentStatus.LocationID
        : undefined;
    }
    if (
      props.companyOptions &&
      props.companyOptions.length > 0 &&
      this.state.init !== true
    ) {
      props.companyOptions.forEach(element => {
        if (currStatus.CompanyID === element.value) {
          currStatus.Company = element;
        }
      });
    }
    if (props.locationOptions && props.locationOptions.length > 0) {
      let found = false;
      props.locationOptions.forEach(element => {
        if (currStatus.LocationID === element.value) {
          currStatus.Location = element;
          found = true;
        }
      });
      if (!found) {
        currStatus.Location = {};
        currStatus.LocationID = -1;
      }
    } else {
      currStatus.Location = {};
    }
    this.setState({
      device: props.device || {},
      companyOptions: props.companyOptions || [],
      locationOptions: props.locationOptions || [],
      status: currStatus,
      updateCompany: props.updateCompany || function () { },
      init:
        this.state.init ||
        (!!currStatus.Company.value ||
          !!currStatus.Location.value)
    });
  }

  updateStatusValue(event) {
    const { status } = this.state;
    status[event.target.id] = event.target.value;
    this.setState({ status });
  }

  updateStatusCompany(selection) {
    const { status } = this.state;
    if (status.CompanyID !== selection.value) {
      status.Company = selection;
      status.CompanyID = selection.value;
      this.state.updateCompany(selection);
      this.setState({ status, locationOptions: [] });
    }
  }

  updateStatusLocation(selection) {
    const { status } = this.state;
    status.Location = selection;
    status.LocationID = selection.value;
    this.setState({ status });
  }

  updateStatusOption(selection) {
    const { status } = this.state;
    status.StatusOption = selection;
    this.setState({ status });
  }

  submit(event) {
    event.preventDefault();
    const currStatus = this.state.status;

    if (currStatus.StatusOption === null) {
      alert("Please select a status.")
      return;
    }

    const data = {
      DeviceSerial: this.state.device.Serial,
      LocationID: currStatus.Location.value,
      CompanyID: currStatus.Company.value,
      StateLabel: currStatus.StatusOption.label,
      StateID: currStatus.StatusOption.value,
      Reason: currStatus.Reason,
    };

    this.props.submit(this.state.device.Serial, data).then(data => {
      this.setState({ redirectTo: `/device/${this.state.device.Serial}` });
      return data;
    });
  }

  renderLocation() {
    return (
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label" htmlFor="Location">
              Current Location
            </label>
            <div className="control">
              <Select
                id="Location"
                disable={this.state.locationOptions.length === 0}
                onChange={this.updateStatusLocation}
                options={this.state.locationOptions}
                value={this.state.status.Location}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <div className="box">
        <form onSubmit={this.submit}>
          <div>
            <div className="columns">
              <div className="column">
                <h1 className="title">
                  New Status for {this.state.device.Serial}
                </h1>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Status">
                    Status
                  </label>
                  <div className="control">
                    <Select
                      id="StatusOption"
                      disable={this.props.statusOptions.length === 0}
                      onChange={this.updateStatusOption}
                      options={this.props.statusOptions}
                      value={this.state.status.StatusOption}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Reason">
                    Reason
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      id="Reason"
                      type="text"
                      onChange={this.updateStatusValue}
                      value={this.state.status.Reason}
                      placeholder="Ex. Device stopped working"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Company">
                    Current Company
                  </label>
                  <div className="control">
                    <Select
                      id="Company"
                      onChange={this.updateStatusCompany}
                      options={this.state.companyOptions}
                      value={this.state.status.Company}
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.renderLocation()}
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

StatusForm.propTypes = {
  companyOptions: PropTypes.array,
  locationOptions: PropTypes.array,
  device: PropTypes.object
};
