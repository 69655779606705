import React from "react";
import { Link } from "react-router-dom";
// import format from "date-fns/format";
// import PropTypes from "prop-types";

const renderStats = data => {
  return (
    <div className="box" key={`stats`}>
      <div className="columns column-hover">
        <div className="column">
          <strong>Number of Devices in Inventory System</strong>
        </div>
        <div className="column is-narrow">{data.TotalDevices || "0"}</div>
      </div>
      <div className="columns column-hover">
        <div className="column">
          <strong>Number of Devices in Datastore</strong>
        </div>
        <div className="column is-narrow">
          {data.DatastoreTotalDevices || "0"}
        </div>
      </div>
      <div className="columns column-hover">
        <div className="column">
          <strong>Number of Devices "Broken"</strong>
        </div>
        <div className="column is-narrow">
          <Link to="/?status[]=BROKEN" className="button is-info">
            {data.BrokenDeviceCount || "0"}
          </Link>
        </div>
      </div>
      <div className="columns column-hover">
        <div className="column">
          <strong>Number of Devices "New"</strong>
        </div>
        <div className="column is-narrow">
          <Link to="/?status[]=NEW" className="button is-info">
            {data.NewDeviceCount || "0"}
          </Link>
        </div>
      </div>
      <div className="columns column-hover">
        <div className="column">
          <strong>Number of Devices "In Use"</strong>
        </div>
        <div className="column is-narrow">
          <Link to="/?status[]=IN%20USE" className="button is-info">
            {data.InUseDeviceCount || "0"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Statistics = ({ isLoaded, data }) => {
  let d = (
    <div className="box" key={`title`}>
      <div className="columns">
        <div className="column">
          <h1 className="title">Stats</h1>
        </div>
      </div>
    </div>
  );
  const content = isLoaded ? (
    renderStats(data)
  ) : (
    <div className="box" key="loading">
      <div className="columns">
        <div className="column">Loading</div>
      </div>
    </div>
  );
  return [d, content];
};

// Analytics.propTypes = {};
